import { Form } from "antd";
import { useWatch } from "antd/es/form/Form";
import YesNoSelect from "../common/YesNoSelect";
import BKDatePicker from "../common/BKDatePicker";

interface IOptionalDoneDateFormProps {
  disabled?: boolean;
}

const OptionalDoneDateForm = (props: IOptionalDoneDateFormProps) => {
  const isDone = useWatch("isDone");

  return (
    <>
      <YesNoSelect
        name="isDone"
        label="Yapılmış mı?"
        initialValue={false}
        disabled={props.disabled}
      />
      {isDone && (
        <Form.Item name="doneDate" label="Aşının Yapılma Tarihi">
          <BKDatePicker />
        </Form.Item>
      )}
    </>
  );
};

export default OptionalDoneDateForm;
