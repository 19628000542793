import { useContext, useRef } from "react";
import { PetCrudApi } from "../../client/Api";
import CrudTable, {
  ICrudTableRefMethods,
} from "../../components/common/crud/CrudTable";
import {
  getPetCPColumns,
  getPetCPFormItems,
} from "../../components/hook/PetCPComponent";
import { ConstantContext } from "../../context";
import { Divider, Space, Typography } from "antd";
import YesNoTag from "../../components/common/YesNoTag";
import { getFormattedDate } from "../../helpers/UtilHelper";
import dayjs from "dayjs";
import CrudTableProcessButton from "../../components/common/crud/CrudTableProcessButton";
import VaccineCalendarGeneratorModal, {
  IVaccineCalendarGeneratorModalRefMethods,
} from "../../components/modal/VaccineCalendarGeneratorModal";
import VaccineIcon from "../../icons/VaccineIcon";

const { Title, Text } = Typography;

const PetCrudPage = () => {
  const constants = useContext(ConstantContext);
  const crudTableRef = useRef<ICrudTableRefMethods>();
  const vaccineCalendarGeneratorModalRef =
    useRef<IVaccineCalendarGeneratorModalRefMethods>();

  return (
    <>
      <CrudTable
        ref={crudTableRef}
        entityLabel="Hasta"
        api={PetCrudApi}
        columns={getPetCPColumns(constants)}
        addFormItems={getPetCPFormItems(constants)}
        editFormItems={getPetCPFormItems(constants, true)}
        extraRowProcess={(row) =>
          !row.isVaccineCalenderCreate && (
            <CrudTableProcessButton
              icon={<VaccineIcon />}
              onClick={() => {
                vaccineCalendarGeneratorModalRef.current?.form.setFieldValue(
                  "pet",
                  row["@id"]
                );
                vaccineCalendarGeneratorModalRef.current?.form.setFieldValue(
                  "birthDate",
                  dayjs(row.birthDate)
                );
                vaccineCalendarGeneratorModalRef.current?.setSpecieId(
                  row.racial.specie.id
                );
                vaccineCalendarGeneratorModalRef.current?.setOpen(true);
              }}
              tooltipText="Aşı Takvimi Oluştur"
            />
          )
        }
        expandable={{
          expandedRowRender: (row) => (
            <>
              <Title level={5}>Cari/Müşteri</Title>
              <Space>
                <Text>
                  Cari/Müşteri: <b>{row.customer.name}</b>
                </Text>
                <Text>
                  Telefon: <b>{row.customer.phone}</b>
                </Text>
                <Text>
                  TC: <b>{row.customer.taxNo}</b>
                </Text>
                <Text>
                  Güncel Bakiye: <b>{row.customer.balance} TL</b>
                </Text>
              </Space>
              <Divider />
              <Title level={5}>Hasta Bilgileri</Title>
              <Space>
                <Text>
                  Oluşturulma Tarihi:{" "}
                  <b>{getFormattedDate(row.createdAt, "DD.MM.YYYY HH:mm")}</b>
                </Text>
                <Text>
                  Son Değişiklik Tarihi:{" "}
                  <b>{getFormattedDate(row.updatedAt, "DD.MM.YYYY HH:mm")}</b>
                </Text>
                <Text>
                  Doğum Tarihi:{" "}
                  <b>
                    {row.birthDate ? getFormattedDate(row.birthDate) : "Yok"}
                  </b>
                </Text>
                <Text>
                  Kısırlaştırılmış mı? <YesNoTag status={row.isNeutered} />
                </Text>
                <Text>
                  Saldırgan mı? <YesNoTag status={row.isAggressive} />
                </Text>
                <Text>
                  Ölmüş mü? <YesNoTag status={row.isDead} />
                </Text>
              </Space>
              {row.note && (
                <>
                  <Title level={5}>Hasta Notu</Title>
                  <Text>{row.note}</Text>
                </>
              )}
            </>
          ),
        }}
        setEditFields={(row) => {
          return {
            ...row,
            customer: row.customer
              ? { label: row.customer.name, value: row.customer["@id"] }
              : undefined,
            birthDate: dayjs(row.birthDate),
          };
        }}
        beforeEditOperation={(values: any) => {
          let racial = null;
          if (values.racial) {
            racial =
              typeof values.racial === "object"
                ? values.racial.value
                : values.racial;
          }

          let customer = null;
          if (values.customer) {
            customer =
              typeof values.customer === "object"
                ? values.customer.value
                : values.customer;
          }

          return { ...values, racial: racial, customer: customer };
        }}
      />
      <VaccineCalendarGeneratorModal
        ref={vaccineCalendarGeneratorModalRef}
        afterOperation={() => {
          crudTableRef.current?.refreshData();
        }}
      />
    </>
  );
};

export default PetCrudPage;
