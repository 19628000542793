import { Form, Input, message, Modal } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { FormInstance } from "antd/lib";
import BKDatePicker from "../common/BKDatePicker";
import { GeneralApi } from "../../client/Api";
import dayjs from "dayjs";
import { required } from "../../helpers/FormRuleHelper";
import { getConstantValue } from "../../helpers/UtilHelper";
import { ConstantContext } from "../../context";

export interface IVaccineCalendarDoneModalRefMethods {
  form: FormInstance;
  setOpen: (open: boolean) => void;
}

interface IVaccineCalendarDoneModalProps {
  afterOperation?: () => void;
}

const VaccineCalendarDoneModal = forwardRef(
  (props: IVaccineCalendarDoneModalProps, ref) => {
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const constants = useContext(ConstantContext);
    const doneDate = useWatch("doneDate", form);
    const renewal = useWatch("renewal", form);

    useImperativeHandle(ref, () => ({ form: form, setOpen: setOpen }));

    const onCancel = () => {
      setOpen(false);
      form.resetFields();
    };

    useEffect(() => {
      if (doneDate) {
        if (renewal === 0) {
          form.resetFields(["rappelDate"]);
        } else {
          form.setFieldsValue({
            rappelDate: dayjs(doneDate).add(renewal, "day"),
          });
        }
      } else {
        form.resetFields(["rappelDate"]);
      }
    }, [doneDate, renewal, form]);

    return (
      <Modal
        title="Yapıldı Olarak İşaretle"
        open={open}
        confirmLoading={loading}
        onCancel={onCancel}
        onOk={() =>
          form.validateFields().then((values) => {
            setLoading(true);
            GeneralApi.vaccineCalendarDone(values)
              .then(async (response) => {
                message.success(response.message);
                onCancel();
                props.afterOperation && props.afterOperation();
              })
              .finally(() => setLoading(false));
          })
        }
        cancelText="Vazgeç"
        okText="Tamam"
      >
        <Form form={form} layout="vertical">
          <Form.Item name="vaccineCalendar" rules={[required]} hidden>
            <Input />
          </Form.Item>
          <Form.Item name="renewal" rules={[required]} hidden>
            <Input />
          </Form.Item>
          <Form.Item
            name="doneDate"
            label="Aşının Yapılma Tarihi"
            tooltip="Bilinmiyorsa boş bırakılabilir."
          >
            <BKDatePicker />
          </Form.Item>
          <Form.Item
            name="rappelDate"
            label={`Sonraki Aşı Tarihi (${getConstantValue(
              renewal,
              constants?.renewals
            )})`}
            tooltip="Sonraki aşı tarihi boş bırakılırsa yeni aşı oluşturulmaz."
          >
            <BKDatePicker />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
);

export default VaccineCalendarDoneModal;
