import {
  Alert,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Spin,
} from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { FormInstance } from "antd/lib";
import { GeneralApi, VaccineCrudApi } from "../../client/Api";
import { required } from "../../helpers/FormRuleHelper";
import BKDatePicker from "../common/BKDatePicker";
import { getOptionsFromEntities } from "../../helpers/UtilHelper";
import OptionalVaccineDateForm from "../form/OptionalVaccineDateForm";
import dayjs from "dayjs";

export interface IVaccineCalendarGeneratorModalRefMethods {
  form: FormInstance;
  setOpen: (open: boolean) => void;
  setSpecieId: (specieId: any) => void;
}

interface IVaccineCalendarGeneratorModalProps {
  afterOperation?: () => void;
}

const VaccineCalendarGeneratorModal = forwardRef(
  (props: IVaccineCalendarGeneratorModalProps, ref) => {
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const [specieId, setSpecieId] = useState(undefined);
    const [options, setOptions] = useState([]);
    const [open, setOpen] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);

    const birthDate = useWatch("birthDate", form);

    useImperativeHandle(ref, () => ({
      form: form,
      setOpen: setOpen,
      setSpecieId: setSpecieId,
    }));

    const onCancel = () => {
      setOpen(false);
      setSpecieId(undefined);
      setOptions([]);
      form.resetFields();
    };

    useEffect(() => {
      if (!specieId) {
        setOptions([]);
        return;
      }

      setDataLoading(true);
      VaccineCrudApi.getAll(
        1,
        10,
        undefined,
        [],
        ["racial.id=[" + specieId + "]"]
      )
        .then((response) => {
          const opt = getOptionsFromEntities(response["hydra:member"]);
          setOptions(opt);
          opt.forEach((option: any, index: number) => {
            form.setFieldValue(["vaccines", index, "vaccine"], option.value);
          });
        })
        .finally(() => setDataLoading(false));
    }, [specieId, form]);

    useEffect(() => {
      if (birthDate) {
        options.forEach((option: any, index: number) => {
          form.setFieldValue(
            ["vaccines", index, "date"],
            dayjs(birthDate).add(option.entity.afterBirth, "day")
          );
        });
      } else {
        options.forEach((_: any, index: number) => {
          form.setFieldValue(["vaccines", index, "date"], undefined);
        });
      }
    }, [birthDate, options, form]);

    return (
      <Modal
        width="40%"
        title="Aşı Takvimi Oluşturucu"
        open={open}
        confirmLoading={loading}
        onCancel={onCancel}
        onOk={() =>
          form.validateFields().then((values) => {
            setLoading(true);
            GeneralApi.vaccineCalendarGenerate(values)
              .then(async (response) => {
                message.success(response.message);
                onCancel();
                props.afterOperation && props.afterOperation();
              })
              .finally(() => setLoading(false));
          })
        }
        cancelText="Vazgeç"
        okText="Oluştur"
      >
        {dataLoading ? (
          <Row style={{ padding: 16 }} justify="center" align="middle">
            <Spin />
          </Row>
        ) : (
          <Form form={form} layout="vertical">
            <Form.Item name="pet" rules={[required]} hidden>
              <Input />
            </Form.Item>
            <Row align="bottom">
              <Col span={8}>
                <Form.Item
                  name="birthDate"
                  label="Başlangıç Tarihi"
                  tooltip="Aşıların başlangıç tarihidir, varsayılan olarak doğum tarihidir."
                  rules={[required]}
                >
                  <BKDatePicker style={{ width: "200px" }} />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Alert
                  style={{ padding: 16, margin: "16px 0px" }}
                  type="warning"
                  message="Aşı takvimine eklenmesini istemediğiniz aşıların Takvime Ekle seçeneğini Hayır'da seçili olarak bırakınız."
                  closable
                  showIcon
                />
              </Col>
            </Row>
            <Form.List name="vaccines">
              {(fields) => (
                <>
                  {fields.map(({ name }, index) => (
                    <Row
                      key={index}
                      gutter={[8, 8]}
                      style={{ width: "100%" }}
                      align="middle"
                    >
                      <Col span={6}>
                        <Form.Item
                          label="Aşı"
                          name={[name, "vaccine"]}
                          rules={[required]}
                        >
                          <Select options={options} disabled />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Takvime Ekle"
                          name={[name, "isAdd"]}
                          rules={[required]}
                          initialValue={false}
                        >
                          <Select
                            options={[
                              { label: "Evet", value: true },
                              { label: "Hayır", value: false },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <Form.Item
                          label="Yapıldı Mı?"
                          name={[name, "isDone"]}
                          rules={[required]}
                          initialValue={false}
                        >
                          <Select
                            options={[
                              { label: "Evet", value: true },
                              { label: "Hayır", value: false },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <OptionalVaccineDateForm name={index} />
                      </Col>
                    </Row>
                  ))}
                </>
              )}
            </Form.List>
          </Form>
        )}
      </Modal>
    );
  }
);

export default VaccineCalendarGeneratorModal;
