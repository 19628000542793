import { Form } from "antd";
import { useWatch } from "antd/es/form/Form";
import BKDatePicker from "../common/BKDatePicker";
import { useEffect } from "react";
import { required } from "../../helpers/FormRuleHelper";

interface IOptionalVaccineDateFormProps {
  name: any;
}

const OptionalVaccineDateForm = (props: IOptionalVaccineDateFormProps) => {
  const form = Form.useFormInstance();
  const isAdd = useWatch(["vaccines", props.name, "isAdd"]);
  const isDone = useWatch(["vaccines", props.name, "isDone"]);

  useEffect(() => {
    if (isDone) form.setFieldValue(["vaccines", props.name, "isAdd"], true);
  }, [isDone, form, props.name]);

  useEffect(() => {
    if (!isAdd) form.setFieldValue(["vaccines", props.name, "isDone"], false);
  }, [isAdd, form, props.name]);

  return (
    <>
      {isAdd && !isDone && (
        <Form.Item
          name={[props.name, "date"]}
          label="Yapılacağı Tarih"
          tooltip="Aşının yapılacağı tarih."
          rules={[required]}
        >
          <BKDatePicker />
        </Form.Item>
      )}
      {isAdd && isDone && (
        <Form.Item
          name={[props.name, "doneDate"]}
          label="Yapılma Tarihi"
          tooltip="Aşının yapıldığı tarih."
          rules={[required]}
        >
          <BKDatePicker />
        </Form.Item>
      )}
    </>
  );
};

export default OptionalVaccineDateForm;
