import { DatePicker } from "antd";

const BKDatePicker = (props: any) => {
  return (
    <DatePicker
      style={{ width: "100%" }}
      showTime={props.showTime}
      format={props.showTime ? "DD.MM.YYYY HH:mm:ss" : "DD.MM.YYYY"}
      {...props}
    />
  );
};

export default BKDatePicker;
