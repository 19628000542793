import Icon from "@ant-design/icons";

const VaccineIcon = (props: any) => (
  <Icon
    {...props}
    component={() => (
      <svg width="20" height="20" viewBox="0 0 512 512">
        <g
          transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M3763 4942 c-140 -50 -221 -175 -211 -326 6 -91 32 -141 117 -229
l65 -68 -254 -254 -254 -254 -133 130 c-164 160 -207 184 -328 184 -67 0 -89
-4 -137 -28 -109 -54 -169 -146 -176 -273 -4 -73 -1 -88 22 -140 15 -32 34
-67 43 -77 16 -17 -26 -61 -799 -835 -884 -887 -844 -841 -869 -979 -18 -94 6
-210 60 -295 l29 -46 -48 -47 c-101 -99 -136 -255 -90 -392 l22 -65 -321 -322
c-318 -319 -321 -323 -321 -364 0 -53 31 -90 83 -99 42 -6 28 -19 503 450
l201 199 29 -15 c130 -65 299 -37 414 69 l59 55 39 -26 c75 -49 147 -67 252
-63 77 3 107 9 160 33 61 27 111 74 877 838 l811 810 47 -41 c64 -56 126 -75
229 -70 66 3 91 10 135 33 109 60 165 164 159 295 -5 115 -27 149 -215 340
l-162 166 254 254 255 255 62 -62 c39 -38 85 -72 121 -89 53 -24 68 -26 145
-22 72 3 96 9 140 33 163 88 215 302 113 456 -17 24 -211 224 -433 445 -318
316 -414 406 -453 424 -69 32 -173 37 -242 12z m152 -186 c11 -3 203 -189 428
-414 l407 -408 0 -45 c0 -91 -78 -154 -160 -130 -46 14 -835 801 -845 844 -9
36 1 86 23 115 19 25 87 53 112 47 8 -2 24 -6 35 -9z m110 -726 l150 -150
-255 -255 -255 -255 -152 152 -153 153 252 252 c139 139 255 253 258 253 3 0
73 -68 155 -150z m-1225 -100 c27 -8 159 -134 608 -583 543 -543 574 -576 579
-615 10 -71 -29 -129 -98 -145 -64 -16 -73 -8 -657 576 -310 309 -570 574
-578 589 -34 64 0 152 66 175 44 15 39 15 80 3z m272 -877 l398 -398 -80 -82
c-44 -46 -84 -83 -88 -83 -5 0 -21 13 -37 30 -45 47 -89 64 -130 50 -44 -14
-65 -44 -65 -91 0 -29 9 -47 44 -86 l45 -50 -117 -116 -117 -117 -100 101
c-55 55 -113 107 -129 116 -62 33 -136 -13 -136 -85 0 -31 12 -47 115 -151
l115 -117 -120 -119 -119 -119 -84 82 c-88 86 -108 95 -161 71 -32 -15 -60
-72 -51 -107 3 -14 41 -61 83 -104 l76 -79 -117 -117 -117 -117 -107 107 c-60
59 -119 111 -134 116 -61 23 -124 -21 -124 -89 0 -37 8 -46 115 -154 l115
-115 -85 -85 c-99 -100 -144 -125 -221 -125 -96 1 -124 21 -378 273 -128 128
-250 253 -271 280 -53 65 -68 135 -45 215 16 57 22 62 818 860 441 441 804
802 807 802 3 0 184 -179 402 -397z m-1869 -1871 l128 -128 -45 -42 c-75 -69
-168 -77 -247 -22 -55 40 -85 147 -59 214 9 25 77 106 89 106 3 0 64 -58 134
-128z"
          />
        </g>
      </svg>
    )}
  />
);

export default VaccineIcon;
