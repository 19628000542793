import {
  DatePicker,
  Form,
  Input,
  Space,
  Tag,
  TimeRangePickerProps,
  Typography,
} from "antd";
import { required } from "../../helpers/FormRuleHelper";
import { getFormattedDate } from "../../helpers/UtilHelper";
import TableSearchFilter from "../common/TableSearchFilter";
import PetSearchSelect from "../form/PetSearchSelect";
import VaccineSearchSelect from "../form/VaccineSearchSelect";
import dayjs from "dayjs";
import BKDatePicker from "../common/BKDatePicker";
import OptionalVaccineForm from "../form/OptionalVaccineForm";
import OptionalDoneDateForm from "../form/OptionalDoneDateForm";

const { Text } = Typography;
const { RangePicker } = DatePicker;

const presets: TimeRangePickerProps["presets"] = [
  {
    label: "Bugün",
    value: [
      dayjs().set("h", 0).set("m", 0).set("s", 0),
      dayjs().set("h", 23).set("m", 59).set("s", 59),
    ],
  },
  {
    label: "Yaklaşan Aşılar",
    value: [
      dayjs().set("h", 0).set("m", 0).set("s", 0),
      dayjs().add(7, "day").set("h", 23).set("m", 59).set("s", 59),
    ],
  },
  {
    label: "Bugünden Önceki Aşılar",
    value: [
      dayjs("1999-01-01").set("h", 0).set("m", 0).set("s", 0),
      dayjs().set("h", 0).set("m", 0).set("s", 0),
    ],
  },
];

export const getVaccineCalendarCPColumns = () => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    {
      title: "Hasta",
      dataIndex: "pet",
      key: "pet.id",
      render: (value: any) => (
        <Space direction="vertical">
          <b>{value.name}</b>
          <b>{value.customer.name}</b>
          <Text>{value.racial.specie.name}</Text>
        </Space>
      ),
      filterDropdown: (props: any) => (
        <TableSearchFilter filterDropdownProps={props}>
          <PetSearchSelect
            style={{ width: 250 }}
            value={
              props.selectedKeys[0]
                ? "/api/pets/" + props.selectedKeys[0]
                : null
            }
            onSelect={(_: any, row: any) => props.setSelectedKeys([row.id])}
          />
        </TableSearchFilter>
      ),
    },
    {
      title: "Aşı",
      dataIndex: "vaccine",
      key: "vaccine.id",
      render: (value: any) => value.name,
      filterDropdown: (props: any) => (
        <TableSearchFilter filterDropdownProps={props}>
          <VaccineSearchSelect
            style={{ width: 250 }}
            value={
              props.selectedKeys[0]
                ? "/api/vaccines/" + props.selectedKeys[0]
                : null
            }
            onSelect={(_: any, row: any) => props.setSelectedKeys([row.id])}
          />
        </TableSearchFilter>
      ),
    },
    {
      title: "Aşı Tarihi",
      dataIndex: "date",
      key: "date",
      sorter: true,
      render: (value: any) => getFormattedDate(value),
      filterDropdown: (props: any) => (
        <TableSearchFilter filterDropdownProps={props}>
          <RangePicker
            showTime
            presets={presets}
            value={props.selectedKeys?.map((selectedKey: any) =>
              dayjs(selectedKey)
            )}
            onChange={(dates) => {
              if (dates === null) return;
              props.setSelectedKeys(
                dates.map((date: any) =>
                  dayjs(date).format("YYYY-MM-DDTHH:mm:ss")
                )
              );
            }}
          />
        </TableSearchFilter>
      ),
    },
    {
      title: "Yapıldı Mı?",
      dataIndex: "isDone",
      key: "isDone",
      filterMultiple: false,
      filters: [
        { text: "Yapıldı", value: true },
        { text: "Yapılmadı", value: false },
      ],
      render: (value: any, row: any) =>
        value ? (
          <>
            <Tag color="green">Yapıldı</Tag>
            <Text style={{ fontSize: 12 }}>
              {row.doneDate
                ? getFormattedDate(row.doneDate) + " tarihinde"
                : "Tarih belirtilmemiş"}
            </Text>
          </>
        ) : (
          <Tag color="red">Yapılmadı</Tag>
        ),
    },
  ];
};

export const getVaccineCalendarCPFormItems = (isEdit = false) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <OptionalVaccineForm />
      <Form.Item
        name="date"
        label="Aşı Tarihi"
        initialValue={dayjs()}
        rules={[required]}
      >
        <BKDatePicker />
      </Form.Item>
      <OptionalDoneDateForm disabled={isEdit} />
    </>
  );
};
