import dayjs from "dayjs";
import { VaccineCalenderCrudApi } from "../../client/Api";
import CrudTable, {
  ICrudTableRefMethods,
} from "../../components/common/crud/CrudTable";
import {
  getVaccineCalendarCPColumns,
  getVaccineCalendarCPFormItems,
} from "../../components/hook/VaccineCalendarCPComponent";
import CrudTableProcessButton from "../../components/common/crud/CrudTableProcessButton";
import { CheckOutlined } from "@ant-design/icons";
import VaccineCalendarDoneModal, {
  IVaccineCalendarDoneModalRefMethods,
} from "../../components/modal/VaccineCalendarDoneModal";
import { useRef } from "react";

const VaccineCalendarCrudPage = () => {
  const crudTableRef = useRef<ICrudTableRefMethods>();
  const vaccineCalendarDoneModalRef =
    useRef<IVaccineCalendarDoneModalRefMethods>();

  return (
    <>
      <CrudTable
        ref={crudTableRef}
        customTableTitle={() => "Aşı Takvimi"}
        entityLabel="Aşı Takvimi"
        api={VaccineCalenderCrudApi}
        columns={getVaccineCalendarCPColumns()}
        addFormItems={getVaccineCalendarCPFormItems()}
        editFormItems={getVaccineCalendarCPFormItems(true)}
        extraRowProcess={(row) => (
          <CrudTableProcessButton
            tooltipText={row.isDone ? "Yapılmış" : "Yapıldı Olarak İşaretle"}
            disabled={row.isDone}
            icon={<CheckOutlined />}
            onClick={() => {
              vaccineCalendarDoneModalRef.current?.form.setFieldsValue({
                vaccineCalendar: row["@id"],
                renewal: row.vaccine.renewal,
                doneDate: dayjs(),
                rappelDate: dayjs().add(row.vaccine.renewal, "day"),
              });
              vaccineCalendarDoneModalRef.current?.setOpen(true);
            }}
          />
        )}
        setEditFields={(row) => {
          return {
            ...row,
            date: dayjs(row.date),
            doneDate: row.doneDate ? dayjs(row.doneDate) : undefined,
            pet: row.pet
              ? { label: row.pet.name, value: row.pet["@id"] }
              : undefined,
            vaccine: row.vaccine
              ? { label: row.vaccine.name, value: row.vaccine["@id"] }
              : undefined,
          };
        }}
        beforeEditOperation={(values: any) => {
          let pet = null;
          if (values.pet) {
            pet =
              typeof values.pet === "object" ? values.pet.value : values.pet;
          }

          let vaccine = null;
          if (values.vaccine) {
            vaccine =
              typeof values.vaccine === "object"
                ? values.vaccine.value
                : values.vaccine;
          }

          return { ...values, pet: pet, vaccine: vaccine };
        }}
      />
      <VaccineCalendarDoneModal
        ref={vaccineCalendarDoneModalRef}
        afterOperation={() => crudTableRef.current?.refreshData()}
      />
    </>
  );
};

export default VaccineCalendarCrudPage;
