import { Col, Form, Input, Row, Select, Tag } from "antd";
import { max, min, required } from "../../helpers/FormRuleHelper";
import {
  getConstantValue,
  getOptionsFromEntities,
} from "../../helpers/UtilHelper";
import RacialTreeSelect from "../form/RacialTreeSelect";
import SearchSelect from "../common/SearchSelect";
import { CustomerCrudApi } from "../../client/Api";
import TableSearchFilter from "../common/TableSearchFilter";
import CustomerSearchSelect from "../form/CustomerSearchSelect";
import YesNoSelect from "../common/YesNoSelect";
import dayjs from "dayjs";
import BKDatePicker from "../common/BKDatePicker";

const { TextArea } = Input;

export const getPetCPColumns = (constants: any) => {
  return [
    { title: "No", dataIndex: "id", key: "id" },
    { title: "Adı", dataIndex: "name", key: "name" },
    {
      title: "Tür/Irk",
      dataIndex: "racial",
      key: "racial.id",
      render: (value: any) => value.specie.name + " / " + value.name,
      filterDropdown: (props: any) => (
        <TableSearchFilter filterDropdownProps={props}>
          <RacialTreeSelect
            style={{ width: 250 }}
            value={
              props.selectedKeys[0]
                ? "/api/racials/" + props.selectedKeys[0]
                : null
            }
            onSelect={(_: any, row: any) => props.setSelectedKeys([row.id])}
          />
        </TableSearchFilter>
      ),
    },
    {
      title: "Cinsiyet",
      dataIndex: "gender",
      key: "gender",
      filterMultiple: false,
      filters: constants?.genders,
      render: (value: any) => getConstantValue(value, constants?.genders),
    },
    {
      title: "Çip No",
      dataIndex: "chipNo",
      key: "chipNo",
      render: (value: any) => value ?? "Yok",
    },
    {
      title: "Cari/Müşteri",
      dataIndex: "customer",
      key: "customer.id",
      render: (value: any) => (
        <Row>
          <Col span={24}>{value.name}</Col>
          {value.phone && <Col span={24}>Tel: {value.phone}</Col>}
        </Row>
      ),
      filterDropdown: (props: any) => (
        <TableSearchFilter filterDropdownProps={props}>
          <CustomerSearchSelect
            style={{ width: 250 }}
            optionValueKey="id"
            value={props.selectedKeys[0]}
            onSelect={(value: any) => props.setSelectedKeys([value])}
          />
        </TableSearchFilter>
      ),
    },
    {
      title: "Aşı Takvimi",
      dataIndex: "isVaccineCalenderCreate",
      key: "isVaccineCalenderCreate",
      filterMultiple: false,
      filters: [
        { text: "Oluşturuldu", value: true },
        { text: "Oluşturulmadı", value: false },
      ],
      render: (value: any) => (
        <Tag color={value ? "green" : "red"}>
          {value ? "Oluşturuldu" : "Oluşturulmadı"}
        </Tag>
      ),
    },
  ];
};

export const getPetCPFormItems = (constants: any, isEdit = false) => {
  return (
    <>
      {isEdit && (
        <Form.Item name="id" rules={[required]} hidden>
          <Input />
        </Form.Item>
      )}
      <Form.Item name="name" label="Adı" rules={[required, min(2), max(255)]}>
        <Input placeholder="Ad giriniz" />
      </Form.Item>
      <Form.Item name="gender" label="Cinsiyet" rules={[required]}>
        <Select placeholder="Cinsiyet seçiniz" options={constants?.genders} />
      </Form.Item>
      <Form.Item label="Tür/Irk" name="racial" rules={[required]}>
        <RacialTreeSelect />
      </Form.Item>
      <Form.Item name="customer" label="Cari/Müşteri" rules={[required]}>
        <SearchSelect
          placeholder="Cari/Müşteri seçiniz"
          searchAction={(searchValue, setOptions, setLoading) =>
            CustomerCrudApi.getAll(1, 10, searchValue)
              .then((response) => {
                setOptions(getOptionsFromEntities(response["hydra:member"]));
              })
              .finally(() => setLoading(false))
          }
        />
      </Form.Item>
      <Form.Item
        name="birthDate"
        label="Doğum Tarihi"
        initialValue={dayjs()}
        rules={[required]}
      >
        <BKDatePicker />
      </Form.Item>
      <YesNoSelect
        name="isNeutered"
        label="Kısırlaştırılmış mı?"
        initialValue={false}
      />
      <YesNoSelect
        name="isAggressive"
        label="Saldırgan mı?"
        initialValue={false}
      />
      <YesNoSelect name="isDead" label="Ölmüş mü?" initialValue={false} />
      {isEdit && (
        <YesNoSelect
          name="isVaccineCalenderCreate"
          label="Aşı takvimi oluşturulmuş mu?"
          initialValue={false}
        />
      )}
      <Form.Item name="chipNo" label="Çip No" rules={[min(1), max(255)]}>
        <Input placeholder="Çip no giriniz" />
      </Form.Item>
      <Form.Item name="note" label="Hasta Notu" rules={[min(1), max(3000)]}>
        <TextArea placeholder="Hasta notu giriniz" />
      </Form.Item>
    </>
  );
};
